import { gql } from 'graphql-request';

import { InstrumentTypeValue } from '@/types/instrument';

export const SNAPSHOT_FOR_HOLDINGS_FRAGMENT_NAME = 'SnapshotForHoldings';

// Consumers must provide `$threeMonthsAgo` via variables
export const SNAPSHOT_FOR_HOLDINGS_FRAGMENT = gql`
    fragment ${SNAPSHOT_FOR_HOLDINGS_FRAGMENT_NAME} on IndexDailySnapshot {
        asOfDate
        netAssetValue
        components: componentsIfNotPrivate {
            value
            instrument {
                symbol
                exchange
                currentSymbol
                companyName
                isCash
                instrumentType
                latestFundamentals {
                    sectorName
                    industryName
                }
                fundamentals(forSymbol: $symbol) {
                    name
                    __typename
                    ... on InstrumentFundamentalDate {
                        dateValue
                    }
                    ... on InstrumentFundamentalDecimal {
                        decimalValue
                    }
                    ... on InstrumentFundamentalString {
                        stringValue
                    }
                }
            }
        }
    }
`;

export type InstrumentFundamentalType = {
    name: string;
} & (
    | {
          __typename: 'InstrumentFundamentalDate';
          dateValue: Optional<string>;
          stringValue?: never;
          decimalValue?: never;
      }
    | {
          __typename: 'InstrumentFundamentalString';
          stringValue: Optional<string>;
          dateValue?: never;
          decimalValue?: never;
      }
    | {
          __typename: 'InstrumentFundamentalDecimal';
          decimalValue: Optional<string>;
          dateValue?: never;
          stringValue?: never;
      }
);

export type SnapshotForHoldingsComponentType = {
    value: string;
    instrument: {
        symbol: string;
        exchange: string;
        currentSymbol: string;
        companyName: string;
        isCash: boolean;
        instrumentType: Optional<InstrumentTypeValue>;
        latestFundamentals: Optional<{
            sectorName: Optional<string>;
            industryName: Optional<string>;
        }>;
        fundamentals: Array<InstrumentFundamentalType>;
    };
};

export type SnapshotForHoldingsType = {
    asOfDate: string;
    components: Optional<Array<SnapshotForHoldingsComponentType>>;
    netAssetValue: string;
};

import { FormatUtcDate } from '@/helpers/dates';
import { GQL_CLIENT } from '@/lib/graphql';
import { FieldErrors } from '@/queries/graphql-types';
import { INDEX_BASIC_INFO_QUERY, INDEX_QUERY, MUTATION_UPDATE_INDEX_OPENGRAPH_IMAGE } from '@/queries/indices';
import type { IndexType, UploadIndexOpengraphImageResult } from '@/types/index';

export interface IndexBasicInfoType {
    id: string;
    name: string;
    symbol: string;
}

export const getIndexBasicInfo = async ({ symbol }: { symbol: string }): Promise<IndexBasicInfoType | FieldErrors> => {
    try {
        const getIndexBasicInfoRequest = await GQL_CLIENT.request(INDEX_BASIC_INFO_QUERY, {
            symbol: symbol.toUpperCase(),
        });

        return getIndexBasicInfoRequest.index as IndexBasicInfoType;
    } catch (error) {
        return error as FieldErrors;
    }
};

export const uploadIndexImage = async ({
    image,
    indexId,
    onSuccess,
    onError,
}: {
    image: File;
    indexId: string;
    onSuccess?: (index: IndexType) => void;
    onError?: (errorMessage: string) => void;
}) => {
    return await GQL_CLIENT.request(MUTATION_UPDATE_INDEX_OPENGRAPH_IMAGE, {
        imageFile: image,
        indexId,
    })
        .then(({ uploadIndexOpengraphImage: data }: { uploadIndexOpengraphImage: UploadIndexOpengraphImageResult }) => {
            if (data.__typename === 'Index') {
                onSuccess?.(data);
            } else if (data.__typename === 'Error') {
                onError?.(data.message);
            }

            return data;
        })
        .catch((error) => {
            onError?.(error.message);
            return error;
        });
};

export const getIndexData = async (symbol: string): Promise<Optional<IndexType>> => {
    try {
        const performanceStartDate = new Date();
        performanceStartDate.setMonth(performanceStartDate.getMonth() - 3);
        const variables = {
            symbol: symbol.toUpperCase(),
            threeMonthsAgo: FormatUtcDate(performanceStartDate, 'yyyy-MM-dd'),
        };
        const response: { index: Optional<IndexType> } = await GQL_CLIENT.request(INDEX_QUERY, variables);
        return response.index;
    } catch (error) {
        return null;
    }
};

import { FULL_MODULES_LIST } from '@/lib/constants/general';
import { RESEARCH_NOTE_ID_QUERY_PARAM } from '@/lib/constants/research';
import {
    AnalystThemeResult,
    CompanyEvaluationModuleType,
    CompanyInfoSectionGroupKeys,
    ResearchNote,
    ResultsModuleType,
} from '@/queries/graphql-types';
import { getTopLevelAnalysisResult } from '@/services/analysis';
import { BaseCompany } from '@/types/company';
import { Nullable } from '@/types/nullable';
import { ResearchItemCategory } from '@/types/research';
import { OrganizationMember } from '@/types/user';

export interface ConstructDefaultPageNameForCompanyPageItemArgs {
    symbol?: never;
    company: BaseCompany;
    isEvaluation: boolean;
    indexName?: never;
    evaluationField?: CompanyEvaluationModuleType;
    instrumentField?: CompanyInfoSectionGroupKeys;
    analystThemeField?: never;
    theme?: never;
}
export interface ConstructDefaultPageNameForAnalysisResultsPageItemArgs {
    symbol?: never;
    company?: never;
    isEvaluation?: never;
    indexName?: never;
    evaluationField?: never;
    instrumentField?: never;
    analystThemeField?: never;
    theme: string;
}
export interface ConstructDefaultPageNameForIndexPageItemArgs {
    symbol: string;
    company?: never;
    isEvaluation?: never;
    indexName: string;
    evaluationField?: never;
    instrumentField?: never;
    analystThemeField?: never;
    theme: string;
}
export interface ConstructDefaultTitleForCompanyPageItemArgs {
    symbol?: never;
    company: BaseCompany;
    isEvaluation: boolean;
    indexName?: never;
    evaluationField?: CompanyEvaluationModuleType;
    instrumentField?: CompanyInfoSectionGroupKeys;
    analystThemeField?: never;
    theme?: never;
}

export interface ConstructDefaultTitleForAnalysisResultsItemArgs {
    symbol?: never;
    company?: never;
    isEvaluation?: never;
    indexName?: never;
    evaluationField?: never;
    instrumentField?: never;
    analystThemeField?: ResultsModuleType;
    theme: string;
}

export interface ConstructDefaultTitleForIndexPageItemArgs {
    symbol: string;
    company?: never;
    isEvaluation?: never;
    indexName: string;
    evaluationField?: never;
    instrumentField?: never;
    analystThemeField?: never;
    theme?: never;
}

export type TitleConstructorArgs =
    | ConstructDefaultTitleForCompanyPageItemArgs
    | ConstructDefaultTitleForAnalysisResultsItemArgs
    | ConstructDefaultTitleForIndexPageItemArgs;

export type PageNameConstructorArgs =
    | ConstructDefaultPageNameForCompanyPageItemArgs
    | ConstructDefaultPageNameForAnalysisResultsPageItemArgs
    | ConstructDefaultPageNameForIndexPageItemArgs;

export const constructDefaultPageNameForCompanyPageItem = ({
    company,
    evaluationField,
    instrumentField,
    isEvaluation,
}: ConstructDefaultPageNameForCompanyPageItemArgs) => {
    let title = `${company.companyName} (${company.symbol})`;

    if (instrumentField || evaluationField) {
        const moduleName =
            (evaluationField && FULL_MODULES_LIST[evaluationField]?.title) ||
            (instrumentField && FULL_MODULES_LIST[instrumentField]?.title);

        title = `${title}: [${moduleName}]`;
    }

    if (isEvaluation) {
        return `Company evaluation: ${title}`;
    }

    return `Company info: ${title}`;
};

export const constructDefaultPageNameForAnalysisResultsPageItem = ({
    theme,
}: ConstructDefaultPageNameForAnalysisResultsPageItemArgs) => `Analysis results: ${theme}`;

export const constructDefaultPageNameForIndexPageItem = ({
    symbol,
    indexName,
}: ConstructDefaultPageNameForIndexPageItemArgs) => `Index: ${indexName} (${symbol})`;

export const constructDefaultTitleForCompanyPageItem = ({
    company,
    isEvaluation,
    instrumentField,
    evaluationField,
}: ConstructDefaultTitleForCompanyPageItemArgs) => {
    const title = `${company.companyName} (${company.symbol})`;

    if (instrumentField || evaluationField) {
        const moduleName =
            (evaluationField && FULL_MODULES_LIST[evaluationField]?.title) ||
            (instrumentField && FULL_MODULES_LIST[instrumentField]?.title);

        return `${title}: ${moduleName}`;
    }

    if (isEvaluation) {
        return `Company evaluation: ${title}`;
    }

    return `Company info: ${title}`;
};

export const constructDefaultTitleForAnalysisResultsItem = ({
    theme,
    analystThemeField,
}: ConstructDefaultTitleForAnalysisResultsItemArgs) => {
    const title = theme;

    if (analystThemeField) {
        const moduleName = FULL_MODULES_LIST[analystThemeField]?.title;

        return `${title}: ${moduleName}`;
    }

    return `Analysis results: ${title}`;
};

export const constructDefaultTitleForIndexPageItem = ({
    symbol,
    indexName,
}: ConstructDefaultTitleForIndexPageItemArgs) => {
    return `${indexName} (${symbol})`;
};

export const titleConstructorMap: {
    [key in ResearchItemCategory]: {
        pageName: (args: PageNameConstructorArgs) => string;
        title: (args: TitleConstructorArgs) => string;
    };
} = {
    [ResearchItemCategory.ANALYSIS_RESULTS]: {
        pageName: (args: PageNameConstructorArgs) =>
            constructDefaultPageNameForAnalysisResultsPageItem(
                args as ConstructDefaultPageNameForAnalysisResultsPageItemArgs
            ),
        title: (args: TitleConstructorArgs) =>
            constructDefaultTitleForAnalysisResultsItem(args as ConstructDefaultTitleForAnalysisResultsItemArgs),
    },
    [ResearchItemCategory.COMPANY_INFO]: {
        pageName: (args: PageNameConstructorArgs) =>
            constructDefaultPageNameForCompanyPageItem(args as ConstructDefaultPageNameForCompanyPageItemArgs),
        title: (args: TitleConstructorArgs) =>
            constructDefaultTitleForCompanyPageItem(args as ConstructDefaultTitleForCompanyPageItemArgs),
    },
    [ResearchItemCategory.COMPANY_EVALUATION]: {
        pageName: (args: PageNameConstructorArgs) =>
            constructDefaultPageNameForCompanyPageItem(args as ConstructDefaultPageNameForCompanyPageItemArgs),
        title: (args: TitleConstructorArgs) =>
            constructDefaultTitleForCompanyPageItem({
                ...args,
                isEvaluation: true,
            } as ConstructDefaultTitleForCompanyPageItemArgs),
    },
    [ResearchItemCategory.INDEX]: {
        pageName: (args: PageNameConstructorArgs) =>
            constructDefaultPageNameForIndexPageItem(args as ConstructDefaultPageNameForIndexPageItemArgs),
        title: (args: TitleConstructorArgs) =>
            constructDefaultTitleForIndexPageItem(args as ConstructDefaultTitleForIndexPageItemArgs),
    },
    [ResearchItemCategory.NONE]: {
        pageName: () => '',
        title: () => '',
    },
};

interface GetResearchNoteTitleAndPageNameArgs {
    category: ResearchItemCategory;
    analystThemeField?: Nullable<ResultsModuleType>;
    company?: Nullable<BaseCompany>;
    evaluationField?: Nullable<CompanyEvaluationModuleType>;
    instrumentField?: Nullable<CompanyInfoSectionGroupKeys>;
    isEvaluation: boolean;
    symbol?: Nullable<string>;
    theme?: Nullable<string>;
    indexName?: Nullable<string>;
}

export const getResearchNoteTitleAndPageName = ({
    category,
    analystThemeField,
    company,
    evaluationField,
    instrumentField,
    isEvaluation,
    symbol,
    theme,
    indexName,
}: GetResearchNoteTitleAndPageNameArgs) => {
    const { title, pageName } = titleConstructorMap[category];
    const researchItemTitle = title({
        analystThemeField,
        company,
        evaluationField,
        indexName,
        instrumentField,
        isEvaluation,
        symbol,
        theme,
    } as TitleConstructorArgs);
    const researchItemPageName = pageName({
        analystThemeField,
        company,
        evaluationField,
        indexName,
        instrumentField,
        isEvaluation,
        symbol,
        theme,
    } as PageNameConstructorArgs);

    return {
        pageName: researchItemPageName,
        title: researchItemTitle,
    };
};

interface GetResearchNoteCategoryArgs {
    analystRequestId?: Nullable<string>;
    evaluationRequestId?: Nullable<string>;
    indexId?: Nullable<string>;
    instrumentId?: Nullable<string>;
}

export const getResearchNoteCategory = ({
    analystRequestId,
    instrumentId,
    evaluationRequestId,
    indexId,
}: GetResearchNoteCategoryArgs): ResearchItemCategory => {
    if (analystRequestId) {
        return ResearchItemCategory.ANALYSIS_RESULTS;
    }

    if (evaluationRequestId) {
        return ResearchItemCategory.COMPANY_EVALUATION;
    }

    if (instrumentId) {
        return ResearchItemCategory.COMPANY_INFO;
    }

    if (indexId) {
        return ResearchItemCategory.INDEX;
    }

    return ResearchItemCategory.NONE;
};

interface isIndexPageSavedToResearchArgs {
    indexId: string;
    researchNotes: Array<ResearchNote>;
}

export const isIndexPageSavedToResearch = ({ indexId, researchNotes = [] }: isIndexPageSavedToResearchArgs) => {
    return researchNotes.find((note) => note.indexId === indexId);
};

interface IsCompanyInfoPageSavedToResearchArgs {
    ticker: string;
    exchange: string;
    researchNotes: Array<ResearchNote>;
}

export const isCompanyInfoPageSavedToResearch = ({
    ticker,
    exchange,
    researchNotes = [],
}: IsCompanyInfoPageSavedToResearchArgs) => {
    return researchNotes.find(
        (note) => note.instrument?.symbol === ticker && note.instrument?.exchange === exchange && !note.instrumentField
    );
};

interface IsCompanyEvaluationPageSavedToResearchArgs {
    ticker: string;
    exchange: string;
    evaluationRequestId: string;
    researchNotes: Array<ResearchNote>;
}

export const isCompanyEvaluationPageSavedToResearch = ({
    ticker,
    exchange,
    evaluationRequestId,
    researchNotes = [],
}: IsCompanyEvaluationPageSavedToResearchArgs) => {
    return researchNotes.find(
        (note) =>
            note.evaluation?.instrument?.symbol === ticker &&
            note.evaluation?.instrument?.exchange === exchange &&
            note.evaluationRequestId === evaluationRequestId &&
            !note.evaluationField
    );
};

interface IsAnalysisResultsPageSavedToResearchArgs {
    analysisRequestId: string;
    researchNotes: Array<ResearchNote>;
}

export const isAnalysisResultsPageSavedToResearch = ({
    analysisRequestId,
    researchNotes = [],
}: IsAnalysisResultsPageSavedToResearchArgs) => {
    // Match the request ID and find the note without a theme field
    return researchNotes.find((note) => note.analystRequestId === analysisRequestId && !note.analystThemeField);
};

interface IsCompanyModuleSavedToResearchArgs {
    ticker: string;
    exchange: string;
    evaluationRequestId?: Nullable<string>;
    evaluationField?: Nullable<CompanyEvaluationModuleType>;
    instrumentField?: Nullable<CompanyInfoSectionGroupKeys>;
    researchNotes: Array<ResearchNote>;
}

export const isCompanyModuleSavedToResearch = ({
    ticker,
    exchange,
    evaluationField,
    instrumentField,
    researchNotes = [],
    evaluationRequestId,
}: IsCompanyModuleSavedToResearchArgs) => {
    if (evaluationRequestId && evaluationField) {
        return researchNotes.find(
            (note) =>
                note.evaluation?.instrument?.symbol === ticker &&
                note.evaluation?.instrument?.exchange === exchange &&
                note.evaluationField === evaluationField &&
                note.evaluationRequestId === evaluationRequestId
        );
    }
    if (instrumentField) {
        return researchNotes.find(
            (note) =>
                note.instrument?.symbol === ticker &&
                note.instrument?.exchange === exchange &&
                note.instrumentField === instrumentField
        );
    }

    return new Error('Either evaluationRequestId && evaluationField or instrumentField must be provided');
};

interface IsAnalysisResultsModuleSavedToResearchArgs {
    analysisRequestId: string;
    analystThemeField: ResultsModuleType;
    researchNotes: Array<ResearchNote>;
}

export const isAnalysisResultsModuleSavedToResearch = ({
    analysisRequestId,
    analystThemeField,
    researchNotes = [],
}: IsAnalysisResultsModuleSavedToResearchArgs) => {
    return researchNotes.find(
        (note) => note.analystRequestId === analysisRequestId && note.analystThemeField === analystThemeField
    );
};

interface GroupedResearchNotesByUserArgs {
    researchNotes: Array<ResearchNote>;
    teamMembers: Array<OrganizationMember>;
}

interface GroupedResearchNotesByUserResult {
    [userId: string]: {
        id: string;
        name: string;
        notes: Array<ResearchNote>;
    };
}

export const groupResearchNotesByUser = ({
    researchNotes = [],
    teamMembers,
}: GroupedResearchNotesByUserArgs): GroupedResearchNotesByUserResult => {
    return teamMembers.reduce((groupedNotes, teamMember) => {
        const { user } = teamMember;

        return {
            ...groupedNotes,
            [user.id]: {
                id: `user-${user.id}`,
                name: user.name,
                notes: researchNotes
                    .filter((researchNote) => researchNote.userId === user.id)
                    .sort((a, b) => {
                        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
                    }),
            },
        };
    }, {});
};

interface GetResearchNoteShareUrlArgs {
    researchNoteId: string;
    pageSourceUrl: string;
}

export const getResearchNoteShareUrl = ({ researchNoteId, pageSourceUrl }: GetResearchNoteShareUrlArgs) => {
    return `${pageSourceUrl}?${RESEARCH_NOTE_ID_QUERY_PARAM}=${researchNoteId}`;
};

export const getInstrumentDataFromResearchNote = (researchNote: ResearchNote) => {
    return {
        companyName: researchNote.instrument?.companyName || researchNote.evaluation?.instrument?.companyName || '',
        exchange: researchNote.instrument?.exchange || researchNote.evaluation?.instrument?.exchange || '',
        symbol: researchNote.instrument?.symbol || researchNote.evaluation?.instrument?.symbol || '',
    };
};

export const constructCurrentResearchNoteState = async (researchData: ResearchNote) => {
    const analysisResult =
        researchData.analystRequestId && (await getTopLevelAnalysisResult(researchData.analystRequestId));
    const category = getResearchNoteCategory(researchData);
    const { pageName } = getResearchNoteTitleAndPageName({
        analystThemeField: researchData.analystThemeField,
        category,
        company:
            researchData.instrument || researchData.evaluation?.instrument
                ? {
                      companyName:
                          researchData.instrument?.companyName || researchData.evaluation?.instrument.companyName || '',
                      exchange: researchData.instrument?.exchange || researchData.evaluation?.instrument.exchange || '',
                      symbol: researchData.instrument?.symbol || researchData.evaluation?.instrument.symbol || '',
                  }
                : null,
        evaluationField: researchData.evaluationField,
        indexName: researchData.index?.name,
        instrumentField: researchData.instrumentField,
        isEvaluation: !!researchData.evaluationRequestId,
        symbol: researchData.index?.symbol,
        theme: (analysisResult as AnalystThemeResult)?.theme,
    });

    return { ...researchData, category, pageName };
};

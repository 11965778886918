import BaseModal from '@/components/global/base-modal';
import Button from '@/components/global/button';
import { FeaturedButton } from '@/components/global/featured-button';
import { type BaseTrackingProperties, ModalTypes } from '@/types/tracking';

type ConfirmModalProps = {
    message: string;
    isOpen: boolean;
    onConfirm: () => void;
    onRequestClose: () => void;
    confirmText?: string;
    trackingProperties?: BaseTrackingProperties;
};

const ConfirmModal = ({
    message,
    isOpen,
    onConfirm,
    onRequestClose,
    confirmText = 'Confirm',
    trackingProperties = {},
}: ConfirmModalProps) => {
    return (
        <BaseModal
            header={message}
            allowClose={false}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            size="1/3"
            trackingProperties={{
                ...trackingProperties,
                modalType: ModalTypes.CONFIRM,
            }}
        >
            <div className="flex justify-center gap-6 p-6">
                <Button
                    // TODO: TRACKING
                    type="action"
                    onClick={onRequestClose}
                    color="transparent"
                    className="px-6 color !text-brand-blue-nu border border-brand-blue-nu font-brand-md !rounded-full"
                    roundedCorners="md"
                >
                    Cancel
                </Button>

                <FeaturedButton
                    // TODO: TRACKING
                    onClick={onConfirm}
                    className="px-6 font-brand-md"
                >
                    {confirmText}
                </FeaturedButton>
            </div>
        </BaseModal>
    );
};

export default ConfirmModal;

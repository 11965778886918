import { GQL_CLIENT } from '@/lib/graphql';
import {
    CreateResearchNoteInputType,
    CreateResearchNoteResult,
    FieldErrors,
    RemoveResearchNoteInput,
    ResearchNote,
    UpdateResearchNoteInput,
} from '@/queries/graphql-types';
import {
    CREATE_RESEARCH_NOTE,
    GET_ANALYSIS_RESULTS_RESEARCH_NOTES,
    GET_COMPANY_EVALUATION_RESEARCH_NOTES,
    GET_COMPANY_INFO_RESEARCH_NOTES,
    GET_INDEX_RESEARCH_NOTES,
    GET_ORGANIZATION_RESEARCH_NOTES,
    GET_RESEARCH_NOTE,
    REMOVE_RESEARCH_NOTE,
    UPDATE_RESEARCH_NOTE,
} from '@/queries/research';

export const createNewResearchNote = async (
    input: CreateResearchNoteInputType
): Promise<CreateResearchNoteResult | FieldErrors> => {
    try {
        const createNewResearchNoteRequest = await GQL_CLIENT.request(CREATE_RESEARCH_NOTE, { input });

        return createNewResearchNoteRequest.createResearchNote as CreateResearchNoteResult;
    } catch (error) {
        return error as FieldErrors;
    }
};

export interface RemoveResearchNoteResult {
    removeResearchNote: boolean;
}

export const removeResearchNote = async ({
    researchNoteId,
}: RemoveResearchNoteInput): Promise<RemoveResearchNoteResult | FieldErrors> => {
    try {
        const removeResearchNoteRequest = await GQL_CLIENT.request(REMOVE_RESEARCH_NOTE, { researchNoteId });

        return removeResearchNoteRequest;
    } catch (error) {
        return error as FieldErrors;
    }
};

export const updateResearchNote = async (input: UpdateResearchNoteInput): Promise<ResearchNote | FieldErrors> => {
    try {
        const updateResearchNoteRequest = await GQL_CLIENT.request(UPDATE_RESEARCH_NOTE, { input });

        return updateResearchNoteRequest.updateResearchNote;
    } catch (error) {
        return error as FieldErrors;
    }
};

export const getAllOrganizationResearchNotes = async (
    organizationId: string
): Promise<Array<ResearchNote> | FieldErrors> => {
    try {
        const getAllOrganizationResearchNotesRequest = await GQL_CLIENT.request(GET_ORGANIZATION_RESEARCH_NOTES, {
            organizationId,
        });

        return getAllOrganizationResearchNotesRequest.researchNotes as Array<ResearchNote>;
    } catch (error) {
        return error as FieldErrors;
    }
};

export const getCompanyEvaluationResearchNotes = async (
    organizationId: string,
    evaluationRequestIds: Array<string>
): Promise<Array<ResearchNote> | FieldErrors> => {
    try {
        const getCompanyEvaluationResearchNotesRequest = await GQL_CLIENT.request(
            GET_COMPANY_EVALUATION_RESEARCH_NOTES,
            {
                evaluationRequestIds,
                organizationId,
            }
        );

        return getCompanyEvaluationResearchNotesRequest.researchNotes as Array<ResearchNote>;
    } catch (error) {
        return error as FieldErrors;
    }
};

export const getCompanyInfoResearchNotes = async (
    organizationId: string,
    instrumentIds: Array<string>
): Promise<Array<ResearchNote> | FieldErrors> => {
    try {
        const getCompanyInfoResearchNotesRequest = await GQL_CLIENT.request(GET_COMPANY_INFO_RESEARCH_NOTES, {
            instrumentIds,
            organizationId,
        });

        return getCompanyInfoResearchNotesRequest.researchNotes as Array<ResearchNote>;
    } catch (error) {
        return error as FieldErrors;
    }
};

export const getAnalysisResultsResearchNotes = async (
    organizationId: string,
    analystRequestIds: Array<string>
): Promise<Array<ResearchNote> | FieldErrors> => {
    try {
        const getAnalysisResultsResearchNotesRequest = await GQL_CLIENT.request(GET_ANALYSIS_RESULTS_RESEARCH_NOTES, {
            analystRequestIds,
            organizationId,
        });

        return getAnalysisResultsResearchNotesRequest.researchNotes as Array<ResearchNote>;
    } catch (error) {
        return error as FieldErrors;
    }
};

export const getIndexResearchNotes = async (
    organizationId: string,
    indexIds: Array<string>
): Promise<Array<ResearchNote> | FieldErrors> => {
    try {
        const getIndexResearchNotesRequest = await GQL_CLIENT.request(GET_INDEX_RESEARCH_NOTES, {
            indexIds,
            organizationId,
        });

        return getIndexResearchNotesRequest.researchNotes as Array<ResearchNote>;
    } catch (error) {
        return error as FieldErrors;
    }
};

export const getResearchNote = async (researchNoteId: string): Promise<ResearchNote | FieldErrors> => {
    try {
        const getResearchNoteRequest = await GQL_CLIENT.request(GET_RESEARCH_NOTE, { id: researchNoteId });

        return getResearchNoteRequest.researchNote as ResearchNote;
    } catch (error) {
        return error as FieldErrors;
    }
};

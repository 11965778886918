import defaultTheme from 'tailwindcss/defaultTheme';

export const colors = {
    'akqa-acid': '#ADFF00',
    'akqa-black': '#181718',
    'akqa-green': '#092119',
    'akqa-green-dark': '#00130D',
    'akqa-green-light': '#869F6A',
    'analyst-black': '#080818',
    'analyst-blue': '#443DFF',
    'analyst-dark-lavender': '#6f79a699',
    'analyst-gray': '#6F79A6',
    'analyst-gray-lighter': '#959DC5',
    'analyst-lavender': '#DDDBFF',
    'analyst-lavender-60': '#dddbff',
    'analyst-lavender-medium': '#A8A3FF',
    'analyst-light-black': '#6F79A64D',
    'analyst-light-gray': '#EEEEF2',
    'analyst-lighter-black': '#272A43',
    'analyst-purple': '#2F27CE',
    'analyst-white': '#FBFBFE',
    black: '#050505',
    'brand-black': '#050505',
    'brand-black-alt': '#0E0E2A',
    'brand-black-light': '#444444',
    'brand-black-med': '#0D0F28',
    'brand-black-off': '#333333',
    'brand-blue': '#1B1DD1',
    'brand-blue-dark': '#020B1E',
    'brand-blue-gray': '#97A7BA',
    'brand-blue-light': '#E8E8FB',
    'brand-blue-nu': '#3366FF',
    'brand-blue-yves': '#0E2CC9',
    'brand-chartreuse': '#CDF11D',
    'brand-danger': '#C72D74',
    'brand-danger-light': '#F7E7ED',
    'brand-gray': '#F2F2F2',
    'brand-gray-dark': '#949494',
    'brand-gray-darker': '#707070',
    'brand-gray-darkest': '#494A5B',
    'brand-gray-light': '#FAFAFA',
    'brand-gray-med': '#C4C4C4',
    'brand-gray-shell': '#C1BDBA',
    'brand-lead-ore': '#97A7BA',
    'brand-light-blue': '#33CCFF',
    'brand-navy': '#001497',
    'brand-navy-light': '#F2F4FF',
    'brand-purple': '#8F7DF8',
    'brand-purple-deep': '#22223C',
    'brand-purple-transparent': '#8f7df824',
    'brand-teal': '#00C8D9',
    'brand-teal-dark': '#00B5C4',
    'brand-teal-light': '#D9F6F8',
    'brand-unicorn': '#6155F6',
    'thematic-blue': '#003FFF',
    'thematic-green': '#46B740',
    'thematic-light-blue': '#ADC1FF',
    'thematic-light-yellow': '#F5FFC0',
    'thematic-neon-yellow': '#ceeb38bf',
    'thematic-orange': '#F79800',
    'thematic-pink': '#FFCBEE',
    'thematic-purple': '#3A31D8',
    'thematic-purple-darker': '#3931D9',
    white: '#FFFFFF',
};

export const breakpoints = {
    ...defaultTheme.screens,
    '2xl': '1440px',
    '3xl': '1920px',
    '4xl': '2560px',
    '5xl': '3840px',
};

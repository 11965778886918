import { colors } from '@/lib/tailwind/constants';
import { CompanyModuleTypes, CompanyValuations } from '@/types/company';

export const COMPANY_MODULES: {
    [key in CompanyModuleTypes]?: {
        moduleType: CompanyModuleTypes;
        title: string;
        pathname: string;
    };
} = {
    [CompanyModuleTypes.DESCRIPTION]: {
        moduleType: CompanyModuleTypes.DESCRIPTION,
        pathname: 'description',
        title: 'Description',
    },
    [CompanyModuleTypes.COMPANY_PERFORMANCE]: {
        moduleType: CompanyModuleTypes.COMPANY_PERFORMANCE,
        pathname: 'performance',
        title: 'Performance',
    },
    [CompanyModuleTypes.DOCUMENTS]: {
        moduleType: CompanyModuleTypes.DOCUMENTS,
        pathname: 'documents',
        title: 'Documents',
    },
    [CompanyModuleTypes.COMPANY_ANALYSIS]: {
        moduleType: CompanyModuleTypes.COMPANY_ANALYSIS,
        pathname: 'analysis',
        title: 'Analysis',
    },
    [CompanyModuleTypes.METRICS]: {
        moduleType: CompanyModuleTypes.METRICS,
        pathname: 'metrics',
        title: 'Metrics',
    },
    [CompanyModuleTypes.INSIGHTS]: {
        moduleType: CompanyModuleTypes.INSIGHTS,
        pathname: 'key-insights',
        title: 'Key insights',
    },
    [CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS]: {
        moduleType: CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS,
        pathname: 'summary',
        title: 'Summary',
    },
    [CompanyModuleTypes.FUNDAMENTALS]: {
        moduleType: CompanyModuleTypes.FUNDAMENTALS,
        pathname: 'fundamentals',
        title: 'Fundamentals',
    },
    [CompanyModuleTypes.FINANCIALS]: {
        moduleType: CompanyModuleTypes.FINANCIALS,
        pathname: 'financial-statements',
        title: 'Financial statements',
    },
    [CompanyModuleTypes.PRICE_HISTORY]: {
        moduleType: CompanyModuleTypes.PRICE_HISTORY,
        pathname: 'price-history',
        title: 'Price history',
    },
    [CompanyModuleTypes.EARNINGS_CALLS]: {
        moduleType: CompanyModuleTypes.EARNINGS_CALLS,
        pathname: 'earnings-reports',
        title: 'Earnings reports',
    },
    [CompanyModuleTypes.FILINGS]: {
        moduleType: CompanyModuleTypes.FILINGS,
        pathname: 'public-filings',
        title: 'Public filings',
    },
    [CompanyModuleTypes.PEERS]: {
        moduleType: CompanyModuleTypes.PEERS,
        pathname: 'comparative-analysis',
        title: 'Comparative analysis',
    },
    [CompanyModuleTypes.NEWS]: {
        moduleType: CompanyModuleTypes.NEWS,
        pathname: 'news',
        title: 'News',
    },
    [CompanyModuleTypes.PEER_FUNDAMENTALS]: {
        moduleType: CompanyModuleTypes.PEER_FUNDAMENTALS,
        pathname: 'peer-fundamentals',
        title: 'Peer fundamentals',
    },
};

// TODO: Move to constants file
export const COMPANY_EVALUTION_DETAIL_PAGE_PATHS: {
    [key in CompanyModuleTypes]?: string;
} = {
    [CompanyModuleTypes.COMPANY_ANALYSIS]: 'analysis',
    [CompanyModuleTypes.INSIGHTS]: 'key-insights',
    [CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS]: 'summary',
    [CompanyModuleTypes.FUNDAMENTALS]: 'fundamentals',
    [CompanyModuleTypes.FINANCIALS]: 'financial-statements',
    [CompanyModuleTypes.PRICE_HISTORY]: 'price-history',
    [CompanyModuleTypes.EARNINGS_CALLS]: 'earnings-reports',
    [CompanyModuleTypes.FILINGS]: 'public-filings',
    [CompanyModuleTypes.PEERS]: 'comparative-analysis',
    [CompanyModuleTypes.NEWS]: 'news',
    [CompanyModuleTypes.PEER_FUNDAMENTALS]: 'peer-fundamentals',
};

// TODO: Move to constants file
export const companyModules: {
    [value in CompanyModuleTypes]?: {
        moduleType: CompanyModuleTypes;
        title: string;
    };
} = {
    [CompanyModuleTypes.COMPANY_ANALYSIS]: {
        moduleType: CompanyModuleTypes.COMPANY_ANALYSIS,
        title: 'Analysis',
    },
    [CompanyModuleTypes.COMPANY_PERFORMANCE]: {
        moduleType: CompanyModuleTypes.COMPANY_PERFORMANCE,
        title: 'Company Performance',
    },
    [CompanyModuleTypes.DESCRIPTION]: {
        moduleType: CompanyModuleTypes.DESCRIPTION,
        title: 'Description',
    },
    [CompanyModuleTypes.METRICS]: {
        moduleType: CompanyModuleTypes.METRICS,
        title: 'Metrics',
    },
    [CompanyModuleTypes.DOCUMENTS]: {
        moduleType: CompanyModuleTypes.DOCUMENTS,
        title: 'Documents',
    },
    [CompanyModuleTypes.INSIGHTS]: {
        moduleType: CompanyModuleTypes.INSIGHTS,
        title: 'Insights',
    },
    [CompanyModuleTypes.FUNDAMENTALS]: {
        moduleType: CompanyModuleTypes.FUNDAMENTALS,
        title: 'Fundamentals',
    },
    [CompanyModuleTypes.EARNINGS_CALLS]: {
        moduleType: CompanyModuleTypes.EARNINGS_CALLS,
        title: 'Earnings Calls',
    },
    [CompanyModuleTypes.FILINGS]: {
        moduleType: CompanyModuleTypes.FILINGS,
        title: 'SEC Filings',
    },
    [CompanyModuleTypes.PEERS]: {
        moduleType: CompanyModuleTypes.PEERS,
        title: 'Comparative analysis',
    },
    [CompanyModuleTypes.COMPANY_EVALUATION_ANALYSIS]: {
        moduleType: CompanyModuleTypes.COMPANY_ANALYSIS,
        title: 'Analysis',
    },
    [CompanyModuleTypes.PRICE_HISTORY]: {
        moduleType: CompanyModuleTypes.PRICE_HISTORY,
        title: 'Price History',
    },
};

// TODO: Move to constants file
export const companyValuationsMap: {
    [key in CompanyValuations]: {
        color: string;
        key: string;
        label: string;
    };
} = {
    [CompanyValuations.FAIR]: {
        color: colors['thematic-orange'],
        key: 'fair',
        label: 'Fairly valued',
    },
    [CompanyValuations.OVER]: {
        color: colors['brand-danger'],
        key: 'over',
        label: 'Overvalued',
    },
    [CompanyValuations.UNDER]: {
        color: colors['thematic-green'],
        key: 'under',
        label: 'Undervalued',
    },
};

export const KEYWORDS =
    'company information, company profile, company summary, stock price, stock price performance, valuation, evaluation, analysis, sector, industry, filings, earnings call transcripts, financial statements, news, documents, metrics, key insights, comparative analysis, peers, price history, earnings reports, public filings, news';
export const EVALUTION_REQUEST_ID_QUERY_PARAM = 'rid';

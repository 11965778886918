export const RESEARCH_NOTE_ID_QUERY_PARAM = 'rnid';

export const RESEARCH_CTA_NEW_FEATURE_STORAGE_KEY = 'research_new_feature_tooltip';
export const RESEARCH_CTA_NEW_FEATURE_DESCRIPTION = [
    <>
        <span className="font-brand-bold text-analyst-blue text-xl">Thematic Research</span>
    </>,
    <>
        Save your work all in one place. Simply click the <span className="font-brand-bold">Bookmark</span> icon to
        later view, edit, annotate, and share your research and insights.
    </>,
];

export const RESEARCH_OPEN_SIDEBAR_NEW_FEATURE_STORAGE_KEY = 'research_sidebar_action_new_feature_tooltip';
export const RESEARCH_OPEN_SIDEBAR_NEW_FEATURE_DESCRIPTION = [
    <>
        <span className="font-brand-bold text-analyst-blue">Thematic Research</span>
    </>,
    <>
        View all of your research in one place. Toggle open the floating actions menu and click{' '}
        <span className="font-brand-bold">Open research sidebar</span> to view your saved research for the current page.
    </>,
];

import { gql } from 'graphql-request';
export const INDEX_CARD_FRAGMENT_NAME = 'IndexCard';

// Consumers must provide `$threeMonthsAgo` via variables
export const INDEX_CARD_FRAGMENT = gql`
    fragment ${INDEX_CARD_FRAGMENT_NAME} on Index {
        id
        name
        symbol
        organization {
            name
            id
            avatarUrl
        }
        threeMonthDailySnapshots: dailySnapshots(minAsOfDate: $threeMonthsAgo) {
            asOfDate
            netAssetValue
            isBackcalculated
        }
        followCount
    }
`;

type SnapshotType = {
    asOfDate: string;
    netAssetValue: string;
    isBackcalculated: boolean;
};

type OrganizationType = {
    name: string;
    id: string;
    avatarUrl: Optional<string>;
};

export type IndexCardType = {
    id: string;
    name: string;
    symbol: string;
    threeMonthDailySnapshots: Array<SnapshotType>;
    organization: OrganizationType;
    followCount: number;
};

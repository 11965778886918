'use client';

import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import ConfirmModal from '@/components/global/confirm-modal';
import { useLoggedInGate } from '@/components/providers/LoggedInGate';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { constructCurrentResearchNoteState } from '@/helpers/research';
import { ANALYST_PATH, ANALYST_REQUEST_ID_QUERY_PARAM_KEY } from '@/lib/constants/analyst';
import { EVALUTION_REQUEST_ID_QUERY_PARAM } from '@/lib/constants/company';
import { RESEARCH_NOTE_ID_QUERY_PARAM } from '@/lib/constants/research';
import {
    AnalystThemeResult,
    CreateResearchNoteInputType,
    CreateResearchNoteResult,
    FieldErrors,
    Organization,
    ResearchNote,
    UpdateResearchNoteInput,
} from '@/queries/graphql-types';
import { getTopLevelAnalysisResult } from '@/services/analysis';
import { getIndexBasicInfo, type IndexBasicInfoType } from '@/services/client/indices';
import { getMatchingInstrument } from '@/services/instrument-client';
import {
    createNewResearchNote,
    getAllOrganizationResearchNotes,
    getAnalysisResultsResearchNotes,
    getCompanyEvaluationResearchNotes,
    getCompanyInfoResearchNotes,
    getIndexResearchNotes,
    getResearchNote,
    removeResearchNote,
    type RemoveResearchNoteResult,
    updateResearchNote,
} from '@/services/research-client';
import { InstrumentType } from '@/types/instrument';
import { Nullable } from '@/types/nullable';
import { ResearchItemCategory } from '@/types/research';
import { type ResearchTrackingProperties } from '@/types/tracking';

interface ResearchProviderProps {
    children: React.ReactNode;
}

export interface ModalStateType {
    props?: Nullable<object>;
}

export enum ResearchSidebarState {
    ADD_EDIT = 'add_edit',
    DETAIL_VIEW = 'detail_view',
    VIEW_ALL = 'view_all',
}

export type AdditionalResearchNoteData = {
    theme?: string;
    pageName: string;
    category: ResearchItemCategory;
};

export type CreateResearchNoteType = CreateResearchNoteInputType & AdditionalResearchNoteData;

export type ResearchNoteType = ResearchNote & AdditionalResearchNoteData;

export type UpdateResearchNoteArgs = UpdateResearchNoteInput & AdditionalResearchNoteData;

export type ResearchNoteState = Nullable<ResearchNoteType | CreateResearchNoteType>;

export interface ResearchContextType {
    addToResearch: (args: CreateResearchNoteType) => void;
    currentResearchItem: Nullable<ResearchNote | CreateResearchNoteInputType>;
    editResearchItem: (args: ResearchNoteType) => void;
    researchNotes: Array<ResearchNote>;
    resetCurrentResearchItem: () => void;
    removeResearchItem: (researchNoteId: string) => void;
    hideResearchSidebar: () => void;
    isSavedResearchItem: (id: string) => boolean;
    isResearchSidebarOpen: boolean;
    openSidebarAndViewAll: () => void;
    saveResearchItem: (args: CreateResearchNoteType) => Promise<void>;
    sidebarMode: `${ResearchSidebarState}`;
    showResearchSidebar: () => void;
    switchToAddEditMode: () => void;
    switchToReadMode: () => void;
    switchToDetailView: (args: ResearchNoteType) => void;
    switchToViewAllMode: () => void;
    toggleResearchSidebar: () => void;
    updateSavedResearchItem: (args: UpdateResearchNoteArgs) => Promise<void>;
}

const useGetResearchNotes = () => {
    const { currentUser } = useLoggedInGate();
    const pathname = usePathname();
    const params = useParams();
    const searchParams = useSearchParams();
    const analystRequestId = searchParams?.get(ANALYST_REQUEST_ID_QUERY_PARAM_KEY);
    const evaluationRequestId = searchParams?.get(EVALUTION_REQUEST_ID_QUERY_PARAM);
    const [researchNotes, setResearchNotes] = useState<Array<ResearchNote>>([]);
    const updateResearchNotes = (updatesNotes: Array<ResearchNote>) => setResearchNotes(updatesNotes);
    const isCompanyPage = (params?.exchange && params?.ticker) || evaluationRequestId;
    const isResearchAnalysisResultsPage = pathname === ANALYST_PATH && analystRequestId;
    const isIndexPage = pathname?.includes('/index') && params?.symbol;

    // If page is a company info or evaluation page, fetch research notes for that
    // company page and update the researchNotes state
    useEffect(() => {
        const fetchCompanyResearchNotes = async (organizationId: string) => {
            const instrument = await getMatchingInstrument({
                exchange: params?.exchange as string,
                ticker: params?.ticker as string,
            });
            const { id } = instrument as InstrumentType;

            if (id) {
                const result = evaluationRequestId
                    ? await getCompanyEvaluationResearchNotes(organizationId, [evaluationRequestId])
                    : await getCompanyInfoResearchNotes(organizationId, [id]);

                if ((result as FieldErrors).__typename !== 'FieldErrors') {
                    setResearchNotes(result as Organization['researchNotes']);
                }
            }
        };

        currentUser?.primaryOrganization?.id &&
            isCompanyPage &&
            fetchCompanyResearchNotes(currentUser?.primaryOrganization?.id);
    }, [currentUser?.primaryOrganization?.id, evaluationRequestId, isCompanyPage, params]);

    // If page is a research analysis results page, fetch research notes for that
    // company page and update the researchNotes state
    useEffect(() => {
        const fetchAnalysisResultsResearchNotes = async (organizationId: string, analystRequestId: string) => {
            const result = await getAnalysisResultsResearchNotes(organizationId, [analystRequestId]);

            if ((result as FieldErrors).__typename !== 'FieldErrors') {
                setResearchNotes(result as Organization['researchNotes']);
            }
        };

        currentUser?.primaryOrganization?.id &&
            isResearchAnalysisResultsPage &&
            fetchAnalysisResultsResearchNotes(currentUser?.primaryOrganization?.id, analystRequestId);
    }, [analystRequestId, currentUser?.primaryOrganization?.id, isResearchAnalysisResultsPage, pathname]);

    // If page is a index page, fetch research notes for that
    // company page and update the researchNotes state
    useEffect(() => {
        const fetchIndexResearchNotes = async (organizationId: string, indexSymbol: string) => {
            const index = await getIndexBasicInfo({ symbol: indexSymbol });
            const result = await getIndexResearchNotes(organizationId, [(index as IndexBasicInfoType).id]);

            if ((result as FieldErrors).__typename !== 'FieldErrors') {
                setResearchNotes(result as Organization['researchNotes']);
            }
        };

        currentUser?.primaryOrganization?.id &&
            isIndexPage &&
            fetchIndexResearchNotes(currentUser?.primaryOrganization?.id, params.symbol as string);
    }, [analystRequestId, currentUser?.primaryOrganization?.id, isIndexPage, params?.symbol, pathname]);

    // If page is the research listing page, fetch all research notes for the current user's organization
    useEffect(() => {
        const fetchAllResearchNotes = async (organizationId: string) => {
            const result = await getAllOrganizationResearchNotes(organizationId);

            if ((result as FieldErrors).__typename !== 'FieldErrors') {
                setResearchNotes(result as Organization['researchNotes']);
            }
        };

        currentUser?.primaryOrganization?.id &&
            params?.organizationId &&
            pathname?.includes('research') &&
            fetchAllResearchNotes(currentUser?.primaryOrganization?.id);
    }, [analystRequestId, currentUser?.primaryOrganization?.id, params?.organizationId, pathname]);

    return { researchNotes, updateResearchNotes };
};

export const ResearchContext = createContext<Nullable<ResearchContextType>>(null);

export const ResearchProvider = ({ children }: ResearchProviderProps) => {
    const searchParams = useSearchParams();
    const { eventTypes, trackManualEvent } = usePosthogTracking();
    const { researchNotes, updateResearchNotes } = useGetResearchNotes();
    const [sidebarMode, setSidebarMode] = useState<ResearchSidebarState>(ResearchSidebarState.VIEW_ALL);
    // Can be either a new research note or an existing one
    const [currentResearchItem, setCurrentResearchItem] = useState<ResearchNoteState>(null);
    const [currentResearchItemIdToRemove, setCurrentResearchItemIdToRemove] = useState<Nullable<string>>(null);
    const [isResearchSidebarOpen, setIsResearchSidebarOpen] = useState(false);
    const [showRemoveResearchItemConfirmModal, setShowRemoveResearchItemConfirmModalState] = useState(false);
    const resetCurrentResearchItem = () => setCurrentResearchItem(null);
    const hideResearchSidebar = () => {
        setIsResearchSidebarOpen(false);

        trackManualEvent({
            eventType: eventTypes.CLOSE_RESEARCH_SIDEBAR_NAV,
        });
    };
    const showResearchSidebar = useCallback(
        (args?: CreateResearchNoteType | ResearchNoteType) => {
            setIsResearchSidebarOpen(true);

            trackManualEvent({
                eventType: eventTypes.OPEN_RESEARCH_SIDEBAR_NAV,
                trackingProperties: {
                    ...args,
                } as ResearchTrackingProperties,
            });
        },
        [eventTypes.OPEN_RESEARCH_SIDEBAR_NAV, trackManualEvent]
    );
    const toggleResearchSidebar = () => setIsResearchSidebarOpen((prev) => !prev);
    const switchToReadMode = () => setSidebarMode(ResearchSidebarState.DETAIL_VIEW);
    const switchToDetailView = useCallback(
        (args: ResearchNoteType) => {
            setCurrentResearchItem(args);
            setSidebarMode(ResearchSidebarState.DETAIL_VIEW);
            !isResearchSidebarOpen && showResearchSidebar(args);
        },
        [isResearchSidebarOpen, showResearchSidebar]
    );
    const switchToViewAllMode = () => {
        setSidebarMode(ResearchSidebarState.VIEW_ALL);
        !isResearchSidebarOpen && showResearchSidebar();
    };
    const switchToAddEditMode = () => {
        setSidebarMode(ResearchSidebarState.ADD_EDIT);
        !isResearchSidebarOpen && showResearchSidebar();
    };
    const addNewNoteToResearchNotesList = (updatedNote: ResearchNote) => {
        updateResearchNotes([...researchNotes, updatedNote]);
    };
    const updateResearchNotesList = (updatedNote: ResearchNote) => {
        const updatedNotes = researchNotes.map((note) => {
            if (note.id === updatedNote.id) {
                return updatedNote;
            }
            return note;
        });
        updateResearchNotes(updatedNotes);
    };
    const removeFromResearchNotesList = async (researchNoteId: string) => {
        const updatedNotes = researchNotes.filter((note) => {
            return note.id !== researchNoteId;
        });
        updateResearchNotes(updatedNotes);
    };
    const addToResearch = (args: CreateResearchNoteType) => {
        showResearchSidebar(args);
        setSidebarMode(ResearchSidebarState.ADD_EDIT);
        setCurrentResearchItem(args);
    };
    const editResearchItem = (args: ResearchNoteType) => {
        showResearchSidebar(args);
        setSidebarMode(ResearchSidebarState.ADD_EDIT);
        setCurrentResearchItem(args);
    };
    const removeAction = async () => {
        const researchNoteId = currentResearchItemIdToRemove as string;
        const result = await removeResearchNote({ researchNoteId });

        setShowRemoveResearchItemConfirmModalState(false);
        if ((result as RemoveResearchNoteResult).removeResearchNote === true) {
            setCurrentResearchItemIdToRemove(null);
            removeFromResearchNotesList(researchNoteId);
            isResearchSidebarOpen && hideResearchSidebar();
            setSidebarMode(ResearchSidebarState.VIEW_ALL);
            resetCurrentResearchItem();
        }
    };
    const openSidebarAndViewAll = () => {
        showResearchSidebar();
        setSidebarMode(ResearchSidebarState.VIEW_ALL);
    };

    const removeResearchItem = async (researchNoteId: string) => {
        setCurrentResearchItemIdToRemove(researchNoteId);
        setShowRemoveResearchItemConfirmModalState(true);
    };
    const saveResearchItem = async (args: CreateResearchNoteType): Promise<void> => {
        const { category, pageName, ...rest } = args;
        const result: CreateResearchNoteResult = await createNewResearchNote(rest);

        if (result.__typename !== 'FieldErrors') {
            const researchNote = result as ResearchNote;
            const analysisResult =
                researchNote.analystRequestId && (await getTopLevelAnalysisResult(researchNote.analystRequestId));

            trackManualEvent({
                eventType: eventTypes.SAVED_NEW_RESEARCH_ITEM,
                trackingProperties: {
                    ...researchNote,
                    theme: (analysisResult as AnalystThemeResult)?.theme,
                },
            });
            setCurrentResearchItem({
                ...result,
                category,
                pageName,
            } as ResearchNoteType);
            addNewNoteToResearchNotesList(result as ResearchNote);
            setSidebarMode(ResearchSidebarState.DETAIL_VIEW);
        }
    };
    const isSavedResearchItem = useCallback(
        (id: string) => researchNotes?.some((item) => item.id === id) || false,
        [researchNotes]
    );
    const updateSavedResearchItem = async (args: UpdateResearchNoteArgs) => {
        const { category, pageName, ...rest } = args;
        const result = await updateResearchNote(rest);

        if (result.__typename !== 'FieldErrors') {
            const researchNote = result as ResearchNote;
            const analysisResult =
                researchNote.analystRequestId && (await getTopLevelAnalysisResult(researchNote.analystRequestId));

            trackManualEvent({
                eventType: eventTypes.UPDATED_RESEARCH_ITEM,
                trackingProperties: {
                    ...researchNote,
                    theme: (analysisResult as AnalystThemeResult)?.theme,
                },
            });
            updateResearchNotesList(result as ResearchNote);
            setCurrentResearchItem({
                ...result,
                category,
                pageName,
            } as ResearchNoteState);
            setSidebarMode(ResearchSidebarState.DETAIL_VIEW);
        }
    };
    const researchContextValue: ResearchContextType = {
        addToResearch,
        currentResearchItem,
        editResearchItem,
        hideResearchSidebar,
        isResearchSidebarOpen,
        isSavedResearchItem,
        openSidebarAndViewAll,
        removeResearchItem,
        researchNotes,
        resetCurrentResearchItem,
        saveResearchItem,
        showResearchSidebar,
        sidebarMode,
        switchToAddEditMode,
        switchToDetailView,
        switchToReadMode,
        switchToViewAllMode,
        toggleResearchSidebar,
        updateSavedResearchItem,
    };
    const researchNoteId = searchParams?.get(RESEARCH_NOTE_ID_QUERY_PARAM);

    useEffect(() => {
        // If researchNoteId is present in URL, fetch the research note and set it as currentResearchItem
        // Also open the sidebar and set the view to detail view
        const fetchResearchNote = async (researchNoteId: string) => {
            const result = await getResearchNote(researchNoteId);

            if ((result as FieldErrors).__typename !== 'FieldErrors') {
                const researchNote = result as ResearchNote;
                const newCurrentResearchNote = await constructCurrentResearchNoteState(researchNote);

                switchToDetailView(newCurrentResearchNote as ResearchNoteType);
                showResearchSidebar();
            }
        };

        researchNoteId &&
            (currentResearchItem as ResearchNoteType)?.id !== researchNoteId &&
            fetchResearchNote(researchNoteId);
    }, [currentResearchItem, researchNoteId, showResearchSidebar, switchToDetailView]);

    return (
        <ResearchContext.Provider value={researchContextValue}>
            {children}
            <ConfirmModal
                isOpen={showRemoveResearchItemConfirmModal}
                message="Want to delete this research? All associated notes will also be lost."
                onConfirm={removeAction}
                onRequestClose={() => setShowRemoveResearchItemConfirmModalState(false)}
            />
        </ResearchContext.Provider>
    );
};

export const useResearch = () => {
    const context: Nullable<ResearchContextType> = useContext(ResearchContext);

    if (!context) {
        throw new Error('useResearch must be used within a ResearchProvider');
    }

    return context;
};

export enum FundamentalField {
    MARKET_CAPITALIZATION_USD = 'MARKET_CAPITALIZATION_USD',
    GROSS_INCOME_MARGIN = 'GROSS_INCOME_MARGIN',
    ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE = 'ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE',
    ENTERPRISE_VALUE_REVENUE_RATIO = 'ENTERPRISE_VALUE_REVENUE_RATIO',
    EBITDA_MARGIN = 'EBITDA_MARGIN',
    NET_REVENUE_RETENTION = 'NET_REVENUE_RETENTION',
}

export enum FundamentalModifier {
    GT = 'GT',
    LT = 'LT',
    prefix = 'prefix',
    suffix = 'suffix',
    gtScale = 'gtScale',
    ltScale = 'ltScale',
}

export type FundamentalsType = {
    [key in FundamentalField]: {
        [key in FundamentalModifier]?: string | number | undefined;
    };
};

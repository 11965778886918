import { GQL_CLIENT } from '@/lib/graphql';
import { RESEARCH_ANALYSIS_TOP_LEVEL_RESULT_FRAGMENT } from '@/queries/analyst';
import { AnalystThemeResult, FieldErrors } from '@/queries/graphql-types';

export const getTopLevelAnalysisResult = async (requestId: string): Promise<AnalystThemeResult | FieldErrors> => {
    try {
        const getTopLevelAnalysisResultRequest = await GQL_CLIENT.request(RESEARCH_ANALYSIS_TOP_LEVEL_RESULT_FRAGMENT, {
            requestId,
        });

        return getTopLevelAnalysisResultRequest.analystThemeResult as AnalystThemeResult;
    } catch (error) {
        return error as FieldErrors;
    }
};

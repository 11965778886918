export enum ResearchItemCategory {
    NONE = 'none',
    COMPANY_INFO = 'company_info',
    COMPANY_EVALUATION = 'company_evaluation',
    ANALYSIS_RESULTS = 'analysis_results',
    INDEX = 'index',
}

export enum ResearchAction {
    ADD = 'add',
    REMOVE = 'remove',
    SHARE = 'share',
    UPDATE = 'update',
}
